import {
  Box,
  Typography,
  styled,
  MenuItem,
  Select,
  InputLabel,
  InputBase,
  Button,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
} from "@mui/material";
import React, { useRef, useState, useContext } from "react";
import TablePagination from "@mui/material/TablePagination";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import "jspdf-autotable";

import AuthContext from "../../context/authContext";
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  width: "200px",
  height: "35px",
  backgroundColor: "#fff",
  margin: "10px 0px",
  padding: "0px 15px",
  borderRadius: "20px",
  "&::placeholder": {
    marginLeft: "10px",
    color: theme.palette.text.secondary, // You can adjust the color as needed
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  width: "150px",
  height: "35px",
  backgroundColor: "#fff",
  margin: "10px 0px",
  padding: "0px 15px",
  borderRadius: "20px",
  "&::placeholder": {
    marginLeft: "10px",
    color: theme.palette.text.secondary, // You can adjust the color as needed
  },
}));
const columns = [
  "S.No",
  "Name",
  "CAF No",
  "Area",
  "STB No",
  "Mobile",
  "Plan",
  "Balance",
  "Last Paid",
  "Status",
  "Payments",
];

const getCurrentDate = () => {
  return new Date().toISOString().split("T")[0];
};

const CustomerReport = () => {
  const { userData } = useContext(AuthContext);
  const tableRef = useRef();
  const [reports, setreports] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const inputRefType = useRef("");
  const inputRefArea = useRef("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

  const handleGetPayments = async () => {
    const area = inputRefArea.current.value;
    const types = inputRefType.current.value;

    // Filter data by area
    let filteredDataByArea = [];
    if (area === "All") {
      filteredDataByArea = userData.customers.filter(
        (item) =>
          item.customer_status === "Active" || item.customer_status === "trash"
      );
    } else {
      const filtercust = userData.customers.filter(
        (item) =>
          item.customer_status === "Active" || item.customer_status === "trash"
      );
      filteredDataByArea = filtercust.filter((customer) =>
        area.includes(customer.area)
      );
    }

    // Filter data by type
    let filteredDataByType = [];
    if (types === "All") {
      filteredDataByType = filteredDataByArea;
    } else {
      filteredDataByType = filteredDataByArea.filter((customer) =>
        types.includes(customer.payment_status)
      );
    }

    // Set the filtered data to state
    setreports(filteredDataByType);
    setSearchData(filteredDataByType);
    setShowTable(true);
  };

  const downloadExcel = () => {
    // Filter selected data
    const selectedData = reports.filter((item) =>
      selectedCheckboxes.includes(item.caf_no)
    );

    // Convert selected data to Excel format
    const worksheet = XLSX.utils.json_to_sheet(selectedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "CustomerReport");

    // Generate Excel file
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Trigger download
    saveAs(data, "CustomerReport.xlsx");
  };

  const handleHeaderCheckboxChange = (event) => {
    if (event.target.checked) {
      // If header checkbox is checked, select all checkboxes
      const selectedIds = reports.map((item) => item.caf_no);
      setSelectedCheckboxes(selectedIds);
    } else {
      // If header checkbox is unchecked, deselect all checkboxes
      setSelectedCheckboxes([]);
    }
  };

  const handleCheckboxChange = (event, id) => {
    const { checked } = event.target;
    if (checked) {
      // If checkbox is checked, add to selectedCheckboxes
      setSelectedCheckboxes((prevSelected) => [...prevSelected, id]);
    } else {
      // If checkbox is unchecked, remove from selectedCheckboxes
      setSelectedCheckboxes((prevSelected) =>
        prevSelected.filter((selectedId) => selectedId !== id)
      );
    }
  };

  const handleSearch = (text) => {
    const searchfilter = searchData;

    // If the search text is empty, reset the reports to the original data
    if (!text.trim()) {
      setreports(searchfilter);
      return;
    }

    const filteredReports = searchfilter.filter((customer) =>
      Object.values(customer).some((value) => {
        if (value === null || value === undefined) return false;
        return value.toString().toLowerCase().includes(text.toLowerCase());
      })
    );

    setreports(filteredReports);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const downloadPDF = () => {};

  // const downloadPDF = () => {
  //   const doc = new jsPDF({ orientation: "landscape" });
  //   const selectedData = reports.filter((item) =>
  //     selectedCheckboxes.includes(item.caf_no)
  //   );
  //   doc.autoTable({
  //     html: selectedData,
  //     theme: "striped",
  //     styles: {
  //       textColor: [0, 0, 0], // White text color for headers
  //       fontSize: 5, // Font size for the text
  //       cellPadding: 2, // Padding for each cell
  //       valign: "middle", // Vertical alignment for all cells
  //       halign: "center", // Horizontal alignment for all cells
  //     },
  //     headStyles: {
  //       fillColor: [66, 133, 244], // Blue fill color for headers
  //       textColor: [255, 255, 255],
  //       fontStyle: "bold", // Bold font style for headers
  //     },
  //     bodyStyles: {
  //       fillColor: false, // No fill color for body
  //     },
  //     columnStyles: {
  //       0: { cellWidth: "auto" }, // Adjust column width automatically
  //     },
  //     margin: { top: 20 }, // Set margin for the PDF
  //   });
  //   doc.save("customers.pdf");
  // };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        height: "100vh",
        padding: "15px 40px",
      }}
    >
      <Typography
        sx={{
          color: "#fbbd2c",
          textAlign: "center",
          fontSize: "20px",
          fontWeight: "bolder",
        }}
      >
        Customer Report
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        {/* Options */}
        <Box sx={{ display: "flex" }}>
          {/* Report Type */}
          <Box
            sx={{
              color: "#fff",
              alignItems: "start",
              margin: "10px 10px",
            }}
          >
            <InputLabel sx={{ color: "#fff" }}>Type of Report</InputLabel>
            <StyledSelect
              displayEmpty
              defaultValue="All"
              inputProps={{
                "aria-label": "Without label",
                ref: inputRefType,
              }}
            >
              <MenuItem value={"All"}>All</MenuItem>
              <MenuItem value={"Paid"}>Paid</MenuItem>
              <MenuItem value={"Unpaid"}>Unpaid</MenuItem>
            </StyledSelect>
          </Box>
          {/* Area Wise */}
          <Box
            sx={{
              color: "#fff",
              alignItems: "start",
              margin: "10px 10px",
            }}
          >
            <InputLabel sx={{ color: "#fff" }}>Area</InputLabel>
            <StyledSelect
              displayEmpty
              defaultValue="All"
              inputProps={{
                "aria-label": "Without label",
                ref: inputRefArea,
              }}
            >
              <MenuItem value={"All"}>All</MenuItem>
              {userData.areas &&
                userData.areas.map((item) => (
                  <MenuItem value={item.area_name} key={item.area_name}>
                    {item.area_name}
                  </MenuItem>
                ))}
            </StyledSelect>
          </Box>
        </Box>

        {/* view Button */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "20px",
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          <Button
            sx={{
              color: "#000",
              backgroundColor: "#fbbd2c",
              height: "40px",
              marginTop: "10px",
              "&:hover": {
                color: "#000",
                backgroundColor: "#fbbd2c",
              },
            }}
            onClick={handleGetPayments}
          >
            view
          </Button>
        </Box>
      </Box>

      {/* List */}
      {showTable ? (
        <>
          {" "}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              flexWrap: "wrap",
            }}
          >
            <Box sx={{ display: "flex", gap: "20px" }}>
              <Button
                sx={{
                  backgroundColor: "#fbbd2c",
                  color: "#000",
                  "&:hover": {
                    backgroundColor: "#fbbd2c",
                    color: "#000",
                  },
                }}
                onClick={downloadExcel}
              >
                Excel
              </Button>

              <Button
                sx={{
                  backgroundColor: "#fbbd2c",
                  color: "#000",
                  "&:hover": {
                    backgroundColor: "#fbbd2c",
                    color: "#000",
                  },
                }}
              >
                PDF
              </Button>
            </Box>
            <Box>
              <StyledInputBase
                type="text"
                placeholder="Search"
                onChange={(e) => handleSearch(e.target.value)}
              />
            </Box>
          </Box>
          <Box sx={{ overflow: "scroll" }}>
            <Table ref={tableRef}>
              <TableHead>
                <TableRow
                  sx={{
                    backgroundColor: "#fbbd2c",
                    position: "sticky",
                    top: 0,
                  }}
                >
                  <TableCell>
                    <input
                      type="checkbox"
                      onChange={handleHeaderCheckboxChange}
                    />
                  </TableCell>
                  {columns.map((item) => (
                    <TableCell key={item}>{item}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {reports
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => (
                    <TableRow key={item._id}>
                      <TableCell>
                        <input
                          type="checkbox"
                          onChange={(event) =>
                            handleCheckboxChange(event, item.caf_no)
                          }
                          checked={selectedCheckboxes.includes(item.caf_no)}
                        />
                      </TableCell>
                      <TableCell sx={{ color: "#fff" }}>
                        {" "}
                        {page * rowsPerPage + index + 1}
                      </TableCell>
                      <TableCell sx={{ color: "#fff" }}>
                        {item.customer_name}
                      </TableCell>
                      <TableCell sx={{ color: "#fff" }}>
                        {item.caf_no.toUpperCase()}
                      </TableCell>
                      <TableCell sx={{ color: "#fff" }}>{item.area}</TableCell>
                      <TableCell sx={{ color: "#fff" }}>
                        {item.stb_no}
                      </TableCell>
                      <TableCell sx={{ color: "#fff" }}>
                        {item.mobile}
                      </TableCell>
                      <TableCell sx={{ color: "#fff" }}>
                        {item.cable_plan}
                      </TableCell>
                      <TableCell sx={{ color: "#fff" }}>
                        {item.cable_balance}
                      </TableCell>
                      <TableCell sx={{ color: "#fff" }}>
                        {(item.payments.length > 0 &&
                          item.payments.slice().reverse()[0].pay_date) ||
                          "New"}
                      </TableCell>
                      <TableCell sx={{ color: "#fff" }}>
                        {item.customer_status}
                      </TableCell>
                      <TableCell sx={{ color: "#fff" }}>
                        {item.payment_status}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {/* Pagination */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <TablePagination
                rowsPerPageOptions={[5, 25, 100, 500, 1000, 2000]}
                component="div"
                count={reports && reports.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{
                  color: "#fbbd2c",
                  "& .MuiSvgIcon-root": {
                    // Targeting all SVG icons
                    fill: "#fbbd2c", // Arrow color
                  },
                }}
              />
            </Box>
          </Box>
        </>
      ) : (
        ""
      )}
    </Box>
  );
};

export default CustomerReport;
