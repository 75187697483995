import {
  Box,
  Typography,
  InputBase,
  Button,
  InputLabel,
  Paper,
} from "@mui/material";
import React, { useRef, useContext } from "react";
import yellow from "../assets/yellow.jpg";
import axios from "../api/axios";
import toast from "react-hot-toast";
import AuthContext from "../context/authContext";

const Account = () => {
  // const { userData } = useAuth();

  const inputRefOldPass = useRef(null);
  const inputRefNewPass = useRef(null);
  const inputRefconfirmPass = useRef(null);
  const { userData, setRefresh } = useContext(AuthContext);

  const handleChangePass = async (id) => {
    if (
      inputRefNewPass.current.value === "" ||
      inputRefOldPass.current.value === "" ||
      inputRefconfirmPass.current.value === ""
    ) {
      toast.error("Kindly fill old and New Password");
    } else if (
      inputRefNewPass.current.value !== inputRefconfirmPass.current.value
    ) {
      toast.error("New Passwords did not Match");
    } else {
      try {
        const res = await axios.put(
          `editpass/${id}`,
          {
            oldpass: inputRefOldPass.current.value,
            newpass: inputRefNewPass.current.value,
          },
          {
            headers: {
              "x-auth-token": localStorage.getItem("token"),
              lcono: userData.lcono,
              username: userData.username,
              role: userData.role,
            },
          }
        );

        if (res.status === 200) {
          toast.success(res.data);
          inputRefNewPass.current.value = "";
          inputRefOldPass.current.value = "";
          inputRefconfirmPass.current.value = "";
          setRefresh(true);
        }
      } catch (error) {
        console.error("Error editing Password:", error);
        toast.error("An error occurred while editing Password");
      }
    }
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        padding: { xs: "5px 10px", lg: "10px 40px" },
        marginTop: "40px",
      }}
    >
      <Typography
        sx={{
          textAlign: "center",
          color: "#fbbd2c",
          fontSize: "20px",
          fontWeight: "bolder",
        }}
      >
        Profile
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "row", sm: "row", md: "row", lg: "column" },
          maxWidth: "100%",
          minHeight: "100vh",
          gap: "40px",
          alignItems: "center",
          marginTop: "40px",
          flexWrap: "wrap",
          justifyContent: { xs: "center", lg: "normal" },
        }}
      >
        {/* customer Image */}
        <Box
          sx={{ maxWidth: "100%", display: "flex", justifyContent: "center" }}
        >
          {" "}
          <img
            variant="soft"
            src={yellow}
            style={{ width: "150px", borderRadius: "50%" }}
            alt="profile"
          />
        </Box>
        {/* customer Details/password */}
        <Box
          sx={{
            display: "flex",
            maxWidth: "100%",
            justifyContent: "space-evenly",
            gap: "30px",
            flexWrap: "wrap",
            paddingBottom: "20px",
          }}
        >
          {/* customer Details */}
          <Box
            sx={{
              border: "2px solid #fbbd2c",
              padding: { xs: "5px", sm: "40px" },
              borderRadius: "20px",
              width: { xs: "280px", sm: "400px" },
            }}
          >
            {/* Network Name */}
            <Box
              sx={{
                display: "flex",
                gap: "20px",
                color: "#fff",
                fontSize: "20px",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontSize: "20px", width: "150px" }}>
                Network Name
              </Typography>
              :
              <Typography sx={{ fontSize: "20px", width: "200px" }}>
                {userData.network_name}
              </Typography>
            </Box>
            {/* Owner Name */}
            <Box
              sx={{
                display: "flex",
                gap: "20px",
                color: "#fff",
                fontSize: "20px",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontSize: "20px", width: "150px" }}>
                LCO No
              </Typography>
              :
              <Typography sx={{ fontSize: "20px", width: "200px" }}>
                {userData.lcono}
              </Typography>
            </Box>
            {/* Owner Name */}
            <Box
              sx={{
                display: "flex",
                gap: "20px",
                color: "#fff",
                fontSize: "20px",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontSize: "20px", width: "150px" }}>
                Owner Name
              </Typography>
              :
              <Typography sx={{ fontSize: "20px", width: "200px" }}>
                {userData.name}
              </Typography>
            </Box>
            {/*Phone */}
            <Box
              sx={{
                display: "flex",
                gap: "20px",
                color: "#fff",
                fontSize: "20px",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontSize: "20px", width: "150px" }}>
                Phone
              </Typography>
              :
              <Typography sx={{ fontSize: "20px", width: "200px" }}>
                {userData.phone}
              </Typography>
            </Box>
            {/* Office Number */}
            <Box
              sx={{
                display: "flex",
                gap: "20px",
                color: "#fff",
                fontSize: "20px",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontSize: "20px", width: "150px" }}>
                Office Number
              </Typography>
              :
              <Typography sx={{ fontSize: "20px", width: "200px" }}>
                {userData.office}
              </Typography>
            </Box>
            {/* Address */}
            <Box
              sx={{
                display: "flex",
                gap: "20px",
                color: "#fff",
                fontSize: "20px",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontSize: "20px", width: "150px" }}>
                Address
              </Typography>
              :
              <Typography sx={{ fontSize: "20px", width: "200px" }}>
                {userData.address}
              </Typography>
            </Box>
          </Box>
          {/* change Password */}

          <Box
            sx={{
              border: "2px solid #fbbd2c",
              padding: { xs: "5px 10px", sm: "40px" },
              borderRadius: "20px",
              width: "280px",
            }}
          >
            <InputLabel
              sx={{
                padding: "10px 10px",

                color: "#fff",
                fontWeight: "bolder",
              }}
            >
              Old Password
            </InputLabel>
            <Paper
              component="form"
              sx={{
                display: "flex",
                alignItems: "center",
                width: 260,

                borderRadius: "30px",
              }}
            >
              <InputBase
                sx={{
                  ml: 2,
                  flex: 1,
                  color: "#000",
                }}
                inputProps={{ "aria-label": "Search", ref: inputRefOldPass }}
              />
            </Paper>
            <InputLabel
              sx={{
                padding: "10px 10px",

                color: "#fff",
                fontWeight: "bolder",
              }}
            >
              New Password
            </InputLabel>
            <Paper
              component="form"
              sx={{
                display: "flex",
                alignItems: "center",
                width: 260,
                borderRadius: "30px",
              }}
            >
              <InputBase
                sx={{
                  ml: 2,
                  flex: 1,
                  color: "#000",
                }}
                inputProps={{ "aria-label": "Search", ref: inputRefNewPass }}
              />
            </Paper>
            <InputLabel
              sx={{
                padding: "10px 10px",
                color: "#fff",
                fontWeight: "bolder",
              }}
            >
              Confirm Password
            </InputLabel>
            <Paper
              component="form"
              sx={{
                display: "flex",
                alignItems: "center",
                width: 260,
                borderRadius: "30px",
              }}
            >
              <InputBase
                sx={{
                  ml: 2,
                  flex: 1,
                  color: "#000",
                }}
                inputProps={{
                  "aria-label": "Search",
                  ref: inputRefconfirmPass,
                }}
              />
            </Paper>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                margin: "20px",
              }}
            >
              <Button
                sx={{
                  width: "260px",
                  backgroundColor: "#fbbd2c",
                  color: "#000",
                  "&:hover": { backgroundColor: "#fbbd2c", color: "#000" },
                }}
                onClick={() => handleChangePass(userData.password)}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Account;
