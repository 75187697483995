import { Box, Avatar, CircularProgress, Button } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import Dropdown from "@mui/joy/Dropdown";
import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import yellow from "../../assets/yellow.jpg";
import { Link, useNavigate } from "react-router-dom";
import { getRoles } from "../../context/RequireAuth";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import MenuIcon from "@mui/icons-material/Menu";
import toast from "react-hot-toast";
import ViewCustomerModal from "../modals/viewcustomermodal";
import AuthContext from "../../context/authContext";
import SidebarModal from "../modals/sidebarmodal";

// const PaperComponent = styled("div")(({ theme }) => ({
//   maxHeight: "200px", // Set the max height to enable scrolling
//   overflow: "auto",
//   backgroundColor: "#fff",
// }));
const Navbar = () => {
  const { userData, setRefresh } = useContext(AuthContext);
  const navigate = useNavigate();
  const [role, setRole] = useState("");
  const [searchCafNo, setSearchCafNo] = useState("");
  const [openCustomerViewModal, setOpenCustomerViewModal] = useState(false);
  const [custViewData, setCustViewData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openSidebarModal, setOpenSidebarModal] = useState(false);
  const handleOpenSideModal = () => setOpenSidebarModal(true);
  const handleCloseSideModal = () => setOpenSidebarModal(false);

  const handleClearSearchField = () => {
    setSearchCafNo("");
  };

  const handleOpenCustomerView = () => setOpenCustomerViewModal(true);
  const handleCloseCustomerView = () => {
    setOpenCustomerViewModal(false);
    handleClearSearchField();
    setRefresh(true);
  };

  const handleSearchCafNo = (e, selectedValue) => {
    e.preventDefault();

    if (!selectedValue) {
      toast.error("Kindly fill CafNo");
      return;
    }
    let searchcaf = selectedValue.toLowerCase();
    let searchcaf1 = searchcaf.split(" ")[0];
    const filteredUsers = userData.customers.filter(
      (cust) => cust.caf_no.toLowerCase() === searchcaf1
    );

    if (filteredUsers.length > 0) {
      filteredUsers[0].payments.reverse();

      setCustViewData(filteredUsers);
      handleOpenCustomerView();
    } else {
      setCustViewData([]);
      toast.error("No Customers Found");
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };
  useEffect(() => {
    setRole(getRoles());
  }, []);

  return (
    <Box
      sx={{
        backgroundImage: "linear-gradient(to right, #0f0c29,#302b63 )",
        width: { xs: "100vw", sm: "100vw", md: "100vw", lg: "88vw" },
        height: { xs: "120px", sm: "70px" },
        color: "#fff",
        display: "flex",
        justifyContent: "space-around",
        flexDirection: "column",
      }}
    >
      <Link to="/dashboard" underline="none">
        <Box
          sx={{
            color: "#fff",
            fontSize: "40px",
            width: "100%",
            alignItems: "center",
            height: { xs: "50px", sm: "80px" },
            justifyContent: "center",
            display: { xs: "flex", sm: "none" },
            fontFamily: "Rasa, serif",
          }}
        >
          MapsApp
        </Box>
      </Link>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box
          sx={{
            minHeight: "100%",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Box>
            <Button
              sx={{
                display: { xs: "block", sm: "block", md: "flex", lg: "none" },
                color: "#fff",

                alignItems: "center",
                justifyContent: "center",

                "&:hover": {
                  backgroundColor: "#fbbd2c",
                  color: "#000",
                },
              }}
              onClick={handleOpenSideModal}
            >
              <MenuIcon sx={{ fontSize: "40px" }} />
            </Button>
          </Box>
          <Stack spacing={2} sx={{ width: 200, marginLeft: "10px" }}>
            <Autocomplete
              sx={{
                display: "flex",
                alignItems: "center",
                width: { xs: "100%", sm: 400 },
                backgroundColor: "#302b63",
                borderRadius: "30px",
              }}
              onChange={(e, value) => {
                setSearchCafNo(value?.caf_no || ""); // Set caf_no when selected
                handleSearchCafNo(e, value?.caf_no || "");
              }}
              value={searchCafNo}
              freeSolo
              defaultValue={null}
              disableClearable
              options={
                (userData &&
                  userData.customers &&
                  userData.customers
                    .filter((item) => item.customer_status === "Active")
                    .map((option) => ({
                      display: `${option.caf_no.toUpperCase()} ${
                        option.mobile
                      } ${option.customer_name}`,
                      caf_no: option.caf_no.toLowerCase(),
                      box_number: option.stb_no?.toLowerCase(), // Hidden field for filtering
                    }))) ||
                []
              }
              filterOptions={(options, { inputValue }) =>
                options.filter((option) =>
                  [option.display, option.box_number] // Include box_number in filter logic
                    .some((field) =>
                      field.toLowerCase().includes(inputValue.toLowerCase())
                    )
                )
              }
              getOptionLabel={(option) =>
                typeof option === "string" ? option : option.display
              }
              renderOption={(props, option) => (
                <li {...props}>
                  {option.display}
                  {/* Box number is part of the data but not displayed */}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  sx={{ color: "#fff" }}
                  {...params}
                  label="Search"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                    endAdornment: (
                      <>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                  InputLabelProps={{
                    style: { color: "silver" }, // Set label color to silver
                  }}
                  onFocus={(e) => e.target.setAttribute("style", "color: #fff")}
                />
              )}
            />
          </Stack>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Dropdown>
            <MenuButton
              sx={{
                border: "none",
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <Avatar size="sm" variant="soft" src={yellow} />
            </MenuButton>
            <Menu sx={{ backgroundColor: "#302b63" }}>
              {role === "client" && (
                <Link to="/dashboard/account">
                  <MenuItem sx={{ color: "#fff" }}>Accounts</MenuItem>
                </Link>
              )}
              {role === "client" && (
                <Link to="/dashboard/settings">
                  <MenuItem sx={{ color: "#fff" }}>Settings</MenuItem>{" "}
                </Link>
              )}

              <Link onClick={handleLogout}>
                <MenuItem sx={{ color: "#fff" }}>Logout</MenuItem>{" "}
              </Link>
            </Menu>
          </Dropdown>
        </Box>
      </Box>
      <ViewCustomerModal
        openCustomerViewModal={openCustomerViewModal}
        custViewData={custViewData}
        handleCloseCustomerView={handleCloseCustomerView}
      />
      <SidebarModal
        openSidebarModal={openSidebarModal}
        handleCloseSideModal={handleCloseSideModal}
      />
    </Box>
  );
};

export default Navbar;
