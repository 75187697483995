import {
  Box,
  Typography,
  Tabs,
  Tab,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Button,
  TableBody,
  Modal,
  InputLabel,
  styled,
  MenuItem,
  Select,
  InputBase,
} from "@mui/material";
import React, { useState, useRef, useEffect, useContext } from "react";
import AddIcon from "@mui/icons-material/Add";
import PropTypes from "prop-types";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Chip from "@mui/material/Chip";
import axios from "../api/axios";
import toast from "react-hot-toast";
import AuthContext from "../context/authContext";

const addProvider_URL = "/addprovider";
const addArea_URL = "/addarea";
const addPlan_URL = "/addplan";
const addMessage_URL = "/addmsg";
const addUser_URL = "/adduser";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const userModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  backgroundImage:
    "linear-gradient(to bottom right, #0f0c29, #302b63, #24243e)",
  color: "#000",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  width: "200px",
  height: "35px",
  backgroundColor: "#fff",
  margin: "10px 0px",
  padding: "0px 15px",
  borderRadius: "20px",
  "&::placeholder": {
    marginLeft: "10px",
    color: theme.palette.text.secondary, // You can adjust the color as needed
  },
}));
const StyledSelect = styled(Select)(({ theme }) => ({
  width: "200px",
  height: "35px",
  backgroundColor: "#fff",
  margin: "10px 0px",
  padding: "0px 15px",
  borderRadius: "20px",
  "&::placeholder": {
    marginLeft: "10px",
    color: theme.palette.text.secondary, // You can adjust the color as needed
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Settings = () => {
  const { userData, setRefresh } = useContext(AuthContext);

  const [value, setValue] = useState(0);
  const [openUserModal, setOpenUserModal] = useState(false);
  const handleOpenUserModal = () => setOpenUserModal(true);
  const handleCloseUserModal = () => setOpenUserModal(false);
  const [userArea, setUserArea] = useState([]);
  const [billGen, setBillGen] = useState([]);
  const [showBillBtn, setShowBillBtn] = useState(false);

  // user State
  const [userEdit, setUserEdit] = useState(false);
  const [user_name, setUser_Name] = useState("");
  const [user_Username, setUser_Username] = useState("");
  const [user_Pass, setUser_pass] = useState("");
  const [user_ID, setUser_ID] = useState();
  // const navigate = useNavigate();

  // Area refs
  const inputRefAreaName = useRef(null);
  const inputRefAreaCode = useRef(null);

  // Plan refs
  const inputRefPlanName = useRef(null);
  const inputRefPlanAmount = useRef(null);
  const inputRefNewAmount = useRef(null);
  const inputRefTypeMSO = useRef(null);

  // message Templates
  const inputRefMessage = useRef(null);
  const inputRefTypeMsgCategory = useRef(null);
  // mso/ISP Type
  const inputRefMsoIspName = useRef(null);

  // HandleAdd Provider
  const handleAddProvider = async () => {
    if (inputRefMsoIspName.current.value === "") {
      toast.error("Kindly fill required data");
    } else {
      const res = await axios.post(
        addProvider_URL,
        {
          providerName: inputRefMsoIspName.current.value,
        },
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
            lcono: userData.lcono,
            username: userData.username,
            role: userData.role,
          },
        }
      );
      if (res.status === 200) {
        toast.success(res.data);
        setRefresh(true);

        inputRefMsoIspName.current.value = "";
      }
    }
  };

  // HandleDelete Provider
  const handleDeleteProvider = async (id) => {
    const res = await axios.delete(`deleteprovider/${id}`, {
      headers: {
        "x-auth-token": localStorage.getItem("token"),
        lcono: userData.lcono,
        username: userData.username,
        role: userData.role,
      },
    });
    if (res.status === 200) {
      toast.success(res.data);
      setRefresh(true);
    }
  };

  // HandleAdd Area
  const handleAddArea = async () => {
    if (
      inputRefAreaName.current.value === "" ||
      inputRefAreaCode.current.value === ""
    ) {
      toast.error("Kindly fill required data");
    } else {
      const res = await axios.post(
        addArea_URL,
        {
          areaName: inputRefAreaName.current.value,
          areaCode: inputRefAreaCode.current.value,
        },
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
            lcono: userData.lcono,
            username: userData.username,
            role: userData.role,
          },
        }
      );
      if (res.status === 200) {
        toast.success(res.data);
        setRefresh(true);

        inputRefAreaName.current.value = "";
        inputRefAreaCode.current.value = "";
      }
    }
  };

  // HandleDelete Area
  const handleDeleteArea = async (id) => {
    const res = await axios.delete(`deletearea/${id}`, {
      headers: {
        "x-auth-token": localStorage.getItem("token"),
        lcono: userData.lcono,
        username: userData.username,
        role: userData.role,
      },
    });
    if (res.status === 200) {
      toast.success(res.data);
      setRefresh(true);
    } else if (res.status === 500) {
      toast.error(res.data.error);
    }
  };

  // HandleAdd Plan
  const handleAddPlan = async () => {
    if (
      inputRefPlanName.current.value === "" ||
      inputRefPlanAmount.current.value === "" ||
      inputRefTypeMSO.current.value === ""
    ) {
      toast.error("Kindly fill required data");
    } else {
      const res = await axios.post(
        addPlan_URL,
        {
          planName: inputRefPlanName.current.value,
          planAmount: inputRefPlanAmount.current.value,

          planCategory: inputRefTypeMSO.current.value,
        },
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
            lcono: userData.lcono,
            username: userData.username,
            role: userData.role,
          },
        }
      );
      if (res.status === 200) {
        toast.success(res.data);
        setRefresh(true);
        inputRefPlanName.current.value = "";
        inputRefPlanAmount.current.value = "";
        inputRefTypeMSO.current.value = "";
      }
    }
  };

  // HandleDelete Plan
  const handleDeletePlan = async (id) => {
    const res = await axios.delete(`deleteplan/${id}`, {
      headers: {
        "x-auth-token": localStorage.getItem("token"),
        lcono: userData.lcono,
        username: userData.username,
        role: userData.role,
      },
    });
    if (res.status === 200) {
      toast.success(res.data);
      setRefresh(true);
    } else if (res.status === 500) {
      toast.error(res.data.error);
    }
  };

  // HandleConvert Plan
  const handleConvertPlan = async (id) => {
    const res = await axios.put(
      `convertplan/${id}`,
      { newPlanAmount: inputRefNewAmount.current.value },
      {
        headers: {
          "x-auth-token": localStorage.getItem("token"),
          lcono: userData.lcono,
          username: userData.username,
          role: userData.role,
        },
      }
    );
    if (res.status === 200) {
      toast.success(res.data);
      setRefresh(true);
    } else if (res.status === 500) {
      toast.error(res.data.error);
    }
  };

  // HandleAdd Message
  const handleAddMessage = async () => {
    if (inputRefMessage.current.value === "") {
      toast.error("Kindly fill required data");
    } else {
      const res = await axios.post(
        addMessage_URL,
        {
          msg: inputRefMessage.current.value,
          msg_category: inputRefTypeMsgCategory.current.value,
        },
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
            lcono: userData.lcono,
            username: userData.username,
            role: userData.role,
          },
        }
      );
      if (res.status === 200) {
        toast.success(res.data);
        setRefresh(true);

        inputRefMessage.current.value = "";
      }
    }
  };

  // HandleDelete Message
  const handleDeleteMessage = async (id) => {
    const res = await axios.delete(`deletemsg/${id}`, {
      headers: {
        "x-auth-token": localStorage.getItem("token"),
        lcono: userData.lcono,
        username: userData.username,
        role: userData.role,
      },
    });
    if (res.status === 200) {
      toast.success(res.data);
      setRefresh(true);
    } else if (res.status === 500) {
      toast.error(res.data.error);
    }
  };

  // HandleAdd User
  const handleAddUser = async () => {
    try {
      if (setUser_Username("") || setUser_Name("") || setUser_pass("")) {
        toast.error("Kindly fill required data");
      } else {
        const res = await axios.post(
          addUser_URL,
          {
            name: user_name,
            username: user_Username,
            user_pass: user_Pass,
            area: userArea,
            lcono: localStorage.getItem("lcono"),
          },
          {
            headers: {
              "x-auth-token": localStorage.getItem("token"),
              lcono: userData.lcono,
              username: userData.username,
              role: userData.role,
            },
          }
        );
        if (res.status === 200) {
          toast.success(res.data);
          setUser_Username("");
          setUser_Name("");
          setUser_pass("");
          setUserArea([]);
          handleCloseUserModal();
          setRefresh(true);
        }
        if (res.status === 400) {
          handleCloseUserModal();
          toast.error("User already found");
        }
      }
    } catch (error) {
      handleCloseUserModal();
      toast.error(error.response.data.error);
    }
  };

  // HandleDelete User
  const handleDeleteUser = async (id) => {
    const res = await axios.delete(`deleteuser/${id}`, {
      headers: {
        "x-auth-token": localStorage.getItem("token"),
        lcono: userData.lcono,
        username: userData.username,
        role: userData.role,
      },
    });
    if (res.status === 200) {
      toast.success(res.data);
      setRefresh(true);
    } else if (res.status === 500) {
      toast.error(res.data.error);
    }
  };

  // HandleEdit User
  const handleEditUserModal = async ({ id, name, username, pass, area }) => {
    handleOpenUserModal();
    setUser_ID(id);
    setUser_Username(username);
    setUser_Name(name);
    setUser_pass(pass);
    setUserArea(area);
  };
  const handleEditUser = async () => {
    try {
      const res = await axios.put(
        `edituser/${user_ID}`,
        {
          name: user_name,
          username: user_Username,
          user_pass: user_Pass,
          area: userArea,
        },
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
            lcono: userData.lcono,
            username: userData.username,
            role: userData.role,
          },
        }
      );

      if (res.status === 200) {
        toast.success(res.data);
        setUser_ID("");
        setUser_Username("");
        setUser_Name("");
        setUser_pass("");
        setUserArea([]);
        handleCloseUserModal();
        setUserEdit(false);
        setRefresh(true);
      } else if (res.status === 500) {
        toast.error(res.data.error);
      }
    } catch (error) {
      handleCloseUserModal();
      console.error("Error editing user:", error);
      toast.error("User already Exist");
    }
  };

  // UseEffects
  useEffect(() => {
    if (userData.bills_generated) {
      setBillGen(userData?.bills_generated.reverse());
      if (userData?.bills_generated.length <= 0) {
        setShowBillBtn(true);
      } else {
        let curDate = new Date().toISOString();
        const billgen = userData?.bills_generated[0];
        const billCheck = billgen.bill_date.split("-")[1];
        const curDatecheck = curDate.split("-")[1];
        if (billCheck !== curDatecheck) {
          setShowBillBtn(true);
        }
      }
    }
  }, [userData]);

  // handle Generate Bill
  const handleGenBill = async () => {
    try {
      const res = await axios.post("/genbill", null, {
        headers: {
          "x-auth-token": localStorage.getItem("token"),
          lcono: localStorage.getItem("lcono"),
        },
      });
      if (res.status === 200) {
        setShowBillBtn(false);
        toast.success("Bill Generated");
        setRefresh(true);
      }
    } catch (error) {
      console.log("Error Generating Bill");
    }
  };

  // function to fetch userData

  // ChangeTabs MUI
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeUserArea = (event) => {
    const {
      target: { value },
    } = event;
    setUserArea(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        marginTop: "40px",
        padding: { xs: "5px 10px", sm: "15px 40px" },
      }}
    >
      <Typography
        sx={{
          textAlign: "center",
          color: "#fbbd2c",
          fontSize: "20px",
          fontWeight: "bolder",
        }}
      >
        Settings
      </Typography>
      <Box>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            maxWidth: "100%",
            overflow: "scroll",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChangeTab}
            aria-label="basic tabs example"
            sx={{
              "& .Mui-selected": {
                color: "#fbbd2c",
                fontWeight: "bold",
                fontSize: "18px",
              },
              "& .MuiTabs-indicator": {
                backgroundColor: "#fbbd2c",
                color: "#fbbd2c",
              },
              overflow: "scroll",
              overflowX: "auto",
              overflowY: "auto",
              whiteSpace: "nowrap",
              display: "flex",
              flexWrap: "nowrap",
              width: "100%",
            }}
          >
            <Tab label="Users" {...a11yProps(0)} sx={{ color: "#fbbd2c" }} />
            <Tab
              label="Templates"
              {...a11yProps(1)}
              sx={{ color: "#fbbd2c" }}
            />
            <Tab label="Bill" {...a11yProps(2)} sx={{ color: "#fbbd2c" }} />
            <Tab label="Plan" {...a11yProps(3)} sx={{ color: "#fbbd2c" }} />
            <Tab label="Area" {...a11yProps(4)} sx={{ color: "#fbbd2c" }} />
            <Tab label="Provider" {...a11yProps(5)} sx={{ color: "#fbbd2c" }} />
          </Tabs>
        </Box>
        {/* Users */}
        <CustomTabPanel value={value} index={0}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <Box>
              <Button
                sx={{
                  color: "#000",
                  backgroundColor: "#fbbd2c",
                  "&:hover": {
                    color: "#000",
                    backgroundColor: "#fbbd2c",
                  },
                }}
                onClick={() => {
                  handleOpenUserModal();
                  setUserEdit(false);
                }}
              >
                <AddIcon /> Add User
              </Button>
            </Box>
            <Box sx={{ overflow: "scroll" }}>
              <Table>
                <TableHead>
                  <TableRow
                    sx={{
                      backgroundColor: "#fbbd2c",
                      color: "#000",
                    }}
                  >
                    <TableCell sx={{ textAlign: "center" }}>S.No</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>Name</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>Username</TableCell>

                    <TableCell sx={{ textAlign: "center", width: "300px" }}>
                      Area
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userData?.users &&
                    userData.users.map((item, index) => (
                      <TableRow sx={{ height: "100px" }} key={item._id}>
                        <TableCell
                          sx={{
                            color: "#fff",
                            textAlign: "center",
                            padding: "0px",
                          }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#fff",
                            textAlign: "center",
                            padding: "0px",
                          }}
                        >
                          {item.name}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#fff",
                            textAlign: "center",
                            padding: "0px",
                          }}
                        >
                          {item.username}
                        </TableCell>

                        <TableCell
                          sx={{
                            color: "#fff",
                            textAlign: "center",
                            whiteSpace: "nowrap",
                            padding: "0px",
                          }}
                        >
                          <Box
                            sx={{
                              width: "300px",
                              overflow: "scroll",
                            }}
                          >
                            <ul
                              style={{
                                listStyle: "none",
                                display: "flex",
                                flexWrap: "wrap",
                                gap: "10px",
                                height: "max-content",
                              }}
                            >
                              {item.area &&
                                item.area.map((areas, index) => (
                                  <li key={index}>
                                    <Typography
                                      sx={{
                                        fontSize: "12px",
                                      }}
                                      key={areas}
                                    >
                                      {areas},
                                    </Typography>
                                  </li>
                                ))}{" "}
                            </ul>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box
                            sx={{
                              color: "#fff",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "0px",
                              gap: "20px",
                            }}
                          >
                            <Button
                              sx={{
                                color: "#000",
                                backgroundColor: "#fbbd2c",
                                "&:hover": {
                                  color: "#000",
                                  backgroundColor: "#fbbd2c",
                                },
                              }}
                              onClick={() => {
                                handleEditUserModal({
                                  id: item._id,
                                  name: item.name,
                                  username: item.username,
                                  pass: item.user_pass,
                                  area: item.area,
                                });
                                setUserEdit(true);
                              }}
                            >
                              <EditIcon />
                            </Button>
                            <Button
                              sx={{
                                color: "#000",
                                backgroundColor: "#fbbd2c",
                                "&:hover": {
                                  color: "#000",
                                  backgroundColor: "#fbbd2c",
                                },
                              }}
                              onClick={() => handleDeleteUser(item._id)}
                            >
                              <DeleteIcon />
                            </Button>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Box>
        </CustomTabPanel>
        {/* Templates */}
        <CustomTabPanel value={value} index={1}>
          <Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-evenly",
                marginTop: "30px",
              }}
            >
              {/* Template */}
              <Box
                sx={{
                  color: "#fff",
                  alignItems: "start",
                  margin: "10px 10px",
                }}
              >
                <InputLabel sx={{ color: "#fff" }}>New Template</InputLabel>
                <StyledInputBase
                  style={{
                    width: "260px",
                    backgroundColor: "#fff",
                    margin: "10px 0px",
                    borderRadius: "20px",
                  }}
                  type="text"
                  inputProps={{ ref: inputRefMessage }}
                />
              </Box>
              {/* Message Type */}
              <Box
                sx={{
                  color: "#fff",
                  alignItems: "start",
                  margin: "10px 10px",
                }}
              >
                <InputLabel sx={{ color: "#fff" }}>Type</InputLabel>
                <StyledSelect
                  displayEmpty
                  defaultValue="Message"
                  inputProps={{
                    "aria-label": "Without label",
                    ref: inputRefTypeMsgCategory,
                  }}
                >
                  <MenuItem value="Message">Message</MenuItem>
                  <MenuItem value="Complaint">Complaint</MenuItem>
                </StyledSelect>
              </Box>

              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Button
                  sx={{
                    backgroundColor: "#fbbd2c",
                    color: "#000",
                    "&:hover": { backgroundColor: "#fbbd2c", color: "#000" },
                  }}
                  onClick={handleAddMessage}
                >
                  <AddIcon /> Add Message
                </Button>
              </Box>
            </Box>
            <Box sx={{ marginTop: "30px", overflow: "scroll" }}>
              <Table>
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#fbbd2c" }}>
                    <TableCell sx={{ color: "#000", whiteSpace: "nowrap" }}>
                      S.No
                    </TableCell>
                    <TableCell sx={{ color: "#000", whiteSpace: "nowrap" }}>
                      Message
                    </TableCell>
                    <TableCell sx={{ color: "#000", whiteSpace: "nowrap" }}>
                      Type
                    </TableCell>

                    <TableCell
                      sx={{
                        color: "#000",
                        textAlign: "center",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userData?.msg_temp &&
                    userData.msg_temp.map((item) => (
                      <TableRow key={item._id}>
                        <TableCell sx={{ color: "#fff" }}>1</TableCell>
                        <TableCell sx={{ color: "#fff" }}>{item.msg}</TableCell>

                        <TableCell sx={{ color: "#fff" }}>
                          {item.msg_category}
                        </TableCell>

                        <TableCell
                          sx={{
                            color: "#fff",
                            textAlign: "center",
                            gap: "20px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <Button
                            sx={{
                              color: "#000",
                              backgroundColor: "#fbbd2c",
                              margin: "0px 10px",
                              "&:hover": {
                                color: "#000",
                                backgroundColor: "#fbbd2c",
                              },
                            }}
                            onClick={() => handleDeleteMessage(item._id)}
                          >
                            {" "}
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Box>
        </CustomTabPanel>
        {/* BILL Generate */}
        <CustomTabPanel value={value} index={2}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "30px",
                justifyContent: "center",
                flexDirection: { xs: "column", sm: "row" },
              }}
            >
              {showBillBtn ? (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Button
                    sx={{
                      backgroundColor: "#fbbd2c",
                      color: "#000",
                      "&:hover": { backgroundColor: "#fbbd2c", color: "#000" },
                    }}
                    onClick={handleGenBill}
                  >
                    <AddIcon /> Generate Bill
                  </Button>
                </Box>
              ) : (
                <Typography sx={{ color: "Red", fontSize: "18px" }}>
                  *Bill Generated for Current Month*
                </Typography>
              )}
            </Box>
            <Box
              sx={{ marginTop: "30px", overflow: "scroll", height: "400px" }}
            >
              <Table>
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#fbbd2c" }}>
                    <TableCell
                      sx={{
                        color: "#000",
                        textAlign: "center",
                        whiteSpace: "nowrap",
                      }}
                    >
                      S.No
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#000",
                        textAlign: "center",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Date
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#000",
                        textAlign: "center",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Total Customers
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#000",
                        textAlign: "center",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Total Amount
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {billGen &&
                    billGen.map((item, index) => (
                      <TableRow key={item._id}>
                        <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                          {index + 1}
                        </TableCell>
                        <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                          {item.bill_date.split("T")[0]}
                        </TableCell>
                        <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                          {item.bill_customer}
                        </TableCell>
                        <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                          {item.bill_amount}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Box>
        </CustomTabPanel>
        {/* Plan */}
        <CustomTabPanel value={value} index={3}>
          <Box
            sx={{
              padding: { xs: "5px 10px", sm: "15px 40px" },
            }}
          >
            <Typography
              sx={{ textAlign: "center", fontSize: "20px", color: "#fbbd2c" }}
            >
              Add Plan
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-evenly",
                marginTop: "30px",
              }}
            >
              {/* Plan Name */}
              <Box
                sx={{
                  color: "#fff",
                  alignItems: "start",
                  margin: "10px 10px",
                }}
              >
                <InputLabel sx={{ color: "#fff" }}>Plan Name</InputLabel>
                <StyledInputBase
                  style={{
                    width: "200px",
                    backgroundColor: "#fff",
                    margin: "10px 0px",
                    borderRadius: "20px",
                  }}
                  placeholder="Plan Name"
                  type="text"
                  inputProps={{ ref: inputRefPlanName }}
                />
              </Box>
              {/* Plan Amount */}
              <Box
                sx={{
                  color: "#fff",
                  alignItems: "start",
                  margin: "10px 10px",
                }}
              >
                <InputLabel sx={{ color: "#fff" }}>Plan Amount</InputLabel>
                <StyledInputBase
                  style={{
                    width: "200px",
                    backgroundColor: "#fff",
                    margin: "10px 0px",
                    borderRadius: "20px",
                  }}
                  placeholder="Plan Amount"
                  type="text"
                  inputProps={{ ref: inputRefPlanAmount }}
                />
              </Box>

              {/* Report Type */}
              <Box
                sx={{
                  color: "#fff",
                  alignItems: "start",
                  margin: "10px 10px",
                }}
              >
                <InputLabel sx={{ color: "#fff" }}>MSO/ISP</InputLabel>
                <StyledSelect
                  displayEmpty
                  defaultValue="none"
                  inputProps={{
                    "aria-label": "Without label",
                    ref: inputRefTypeMSO,
                  }}
                >
                  <MenuItem value="none">None</MenuItem>

                  {userData?.mso_isp &&
                    userData.mso_isp.map((item) => (
                      <MenuItem value={item.provider_name} key={item._id}>
                        {item.provider_name}
                      </MenuItem>
                    ))}
                </StyledSelect>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Button
                  sx={{
                    backgroundColor: "#fbbd2c",
                    color: "#000",
                    "&:hover": { backgroundColor: "#fbbd2c", color: "#000" },
                  }}
                  onClick={handleAddPlan}
                >
                  <AddIcon /> Add Plan
                </Button>
              </Box>
            </Box>
            <Box sx={{ marginTop: "30px", overflow: "scroll" }}>
              <Table>
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#fbbd2c" }}>
                    <TableCell sx={{ color: "#000", whiteSpace: "nowrap" }}>
                      S.No
                    </TableCell>
                    <TableCell sx={{ color: "#000", whiteSpace: "nowrap" }}>
                      MSO/ISP Name
                    </TableCell>
                    <TableCell sx={{ color: "#000", whiteSpace: "nowrap" }}>
                      Plan Name
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#000",
                        textAlign: "center",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Plan Amount
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#000",
                        textAlign: "center",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Customer Count
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#000",
                        textAlign: "center",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Convert
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#000",
                        textAlign: "center",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userData?.plans &&
                    userData.plans.map((item, index) => (
                      <TableRow key={item._id}>
                        <TableCell sx={{ color: "#fff" }}>
                          {index + 1}
                        </TableCell>
                        <TableCell sx={{ color: "#fff" }}>
                          {item.mso_isp}
                        </TableCell>

                        <TableCell sx={{ color: "#fff" }}>
                          {item.plan_name}
                        </TableCell>
                        <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                          {item.plan_amount}
                        </TableCell>
                        <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                          {userData.customers &&
                            userData.customers.filter(
                              (pays) =>
                                item.plan_amount === pays.cable_plan &&
                                pays.customer_status === "Active"
                            ).length}
                        </TableCell>
                        <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                          <InputBase
                            sx={{
                              ml: 2,
                              flex: 1,
                              color: "#000",
                              backgroundColor: "#fff",
                              borderRadius: "10px",
                              padding: "0px 10px",
                              width: "80px",
                            }}
                            placeholder="New Plan Amount"
                            inputProps={{
                              "aria-label": "Search",
                              ref: inputRefNewAmount,
                            }}
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#fff",
                            textAlign: "center",
                            gap: "20px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <Button
                            sx={{
                              color: "#000",
                              backgroundColor: "#fbbd2c",
                              margin: "0px 10px",
                              "&:hover": {
                                color: "#000",
                                backgroundColor: "#fbbd2c",
                              },
                            }}
                            onClick={() => handleConvertPlan(item._id)}
                          >
                            {" "}
                            Convert
                          </Button>
                          <Button
                            sx={{
                              color: "#000",
                              backgroundColor: "#fbbd2c",
                              margin: "0px 10px",
                              "&:hover": {
                                color: "#000",
                                backgroundColor: "#fbbd2c",
                              },
                            }}
                            onClick={() => handleDeletePlan(item._id)}
                          >
                            {" "}
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Box>
        </CustomTabPanel>
        {/* Area */}
        <CustomTabPanel value={value} index={4}>
          <Box
            sx={{
              minHeight: "100vh",
              padding: { xs: "5px 10px", sm: "15px 40px" },
            }}
          >
            <Typography
              sx={{ textAlign: "center", fontSize: "20px", color: "#fbbd2c" }}
            >
              Add Area
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-evenly",
                marginTop: "30px",
              }}
            >
              {/* Area Name */}
              <Box
                sx={{
                  color: "#fff",
                  alignItems: "start",
                  margin: "10px 10px",
                }}
              >
                <InputLabel sx={{ color: "#fff" }}>Area Name</InputLabel>
                <StyledInputBase
                  style={{
                    width: "200px",
                    backgroundColor: "#fff",
                    margin: "10px 0px",
                    borderRadius: "20px",
                  }}
                  placeholder="Area Name"
                  type="text"
                  inputProps={{ ref: inputRefAreaName }}
                />
              </Box>
              {/* Area Code */}
              <Box
                sx={{
                  color: "#fff",
                  alignItems: "start",
                  margin: "10px 10px",
                }}
              >
                <InputLabel sx={{ color: "#fff" }}>Area Code</InputLabel>
                <StyledInputBase
                  style={{
                    width: "200px",
                    backgroundColor: "#fff",
                    margin: "10px 0px",
                    borderRadius: "20px",
                  }}
                  placeholder="Area Code"
                  type="text"
                  inputProps={{ ref: inputRefAreaCode }}
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Button
                  sx={{
                    backgroundColor: "#fbbd2c",
                    color: "#000",
                    "&:hover": { backgroundColor: "#fbbd2c", color: "#000" },
                  }}
                  onClick={handleAddArea}
                >
                  <AddIcon /> Add Area
                </Button>
              </Box>
            </Box>
            <Box sx={{ marginTop: "30px", overflow: "scroll" }}>
              <Table>
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#fbbd2c" }}>
                    <TableCell sx={{ color: "#000", whiteSpace: "nowrap" }}>
                      S.No
                    </TableCell>
                    <TableCell sx={{ color: "#000", whiteSpace: "nowrap" }}>
                      Area Name
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#000",
                        textAlign: "center",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Area Code
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#000",
                        textAlign: "center",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Customers
                    </TableCell>

                    <TableCell
                      sx={{
                        color: "#000",
                        textAlign: "center",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userData?.areas &&
                    userData.areas.map((item, index) => (
                      <TableRow key={item._id}>
                        <TableCell sx={{ color: "#fff", whiteSpace: "nowrap" }}>
                          {index + 1}
                        </TableCell>
                        <TableCell sx={{ color: "#fff", whiteSpace: "nowrap" }}>
                          {item.area_name}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#fff",
                            textAlign: "center",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {item.area_code}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#fff",
                            textAlign: "center",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {userData.customers &&
                            userData.customers.filter(
                              (areas) =>
                                areas.area === item.area_name &&
                                areas.customer_status === "Active"
                            ).length}
                        </TableCell>

                        <TableCell
                          sx={{
                            color: "#fff",
                            textAlign: "center",
                            gap: "20px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <Button
                            sx={{
                              color: "#000",
                              backgroundColor: "#fbbd2c",
                              margin: "0px 10px",
                              "&:hover": {
                                color: "#000",
                                backgroundColor: "#fbbd2c",
                              },
                            }}
                            onClick={() => handleDeleteArea(item._id)}
                          >
                            {" "}
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Box>
        </CustomTabPanel>
        {/* Provider */}
        <CustomTabPanel value={value} index={5}>
          <Box
            sx={{
              height: "100vh",
              padding: { xs: "5px 10px", sm: "15px 40px" },
            }}
          >
            <Typography
              sx={{ textAlign: "center", fontSize: "20px", color: "#fbbd2c" }}
            >
              Add MSO/ISP
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-evenly",
                marginTop: "30px",
              }}
            >
              {/* Area Name */}
              <Box
                sx={{
                  color: "#fff",
                  alignItems: "start",
                  margin: "10px 10px",
                }}
              >
                <InputLabel sx={{ color: "#fff" }}>MSO/ISP Name</InputLabel>
                <StyledInputBase
                  style={{
                    width: "200px",
                    backgroundColor: "#fff",
                    margin: "10px 0px",
                    borderRadius: "20px",
                  }}
                  placeholder="MSO/ISP Name"
                  type="text"
                  inputProps={{ ref: inputRefMsoIspName }}
                />
              </Box>

              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Button
                  sx={{
                    backgroundColor: "#fbbd2c",
                    color: "#000",
                    "&:hover": { backgroundColor: "#fbbd2c", color: "#000" },
                  }}
                  onClick={handleAddProvider}
                >
                  <AddIcon /> Add Provider
                </Button>
              </Box>
            </Box>
            <Box sx={{ marginTop: "30px", overflow: "scroll" }}>
              <Table>
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#fbbd2c" }}>
                    <TableCell
                      sx={{
                        color: "#000",
                        whiteSpace: "nowrap",
                        textAlign: "center",
                      }}
                    >
                      S.No
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#000",
                        whiteSpace: "nowrap",
                        textAlign: "center",
                      }}
                    >
                      Provider Name
                    </TableCell>

                    <TableCell
                      sx={{
                        color: "#000",
                        textAlign: "center",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Customers
                    </TableCell>

                    <TableCell
                      sx={{
                        color: "#000",
                        textAlign: "center",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userData?.mso_isp &&
                    userData.mso_isp.map((item, index) => (
                      <TableRow key={item._id}>
                        <TableCell
                          sx={{
                            color: "#fff",
                            whiteSpace: "nowrap",
                            textAlign: "center",
                          }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#fff",
                            whiteSpace: "nowrap",
                            textAlign: "center",
                          }}
                        >
                          {item.provider_name}
                        </TableCell>

                        <TableCell
                          sx={{
                            color: "#fff",
                            textAlign: "center",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {userData.customers &&
                            userData.customers.filter(
                              (prov) =>
                                prov.stb_provider === item.provider_name &&
                                prov.customer_status === "Active"
                            ).length}
                        </TableCell>

                        <TableCell
                          sx={{
                            color: "#fff",
                            textAlign: "center",
                            gap: "20px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <Button
                            sx={{
                              color: "#000",
                              backgroundColor: "#fbbd2c",
                              margin: "0px 10px",
                              "&:hover": {
                                color: "#000",
                                backgroundColor: "#fbbd2c",
                              },
                            }}
                            onClick={() => handleDeleteProvider(item._id)}
                          >
                            {" "}
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Box>
        </CustomTabPanel>
      </Box>
      <Modal
        open={openUserModal}
        onClose={handleCloseUserModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={userModalStyle}>
          <Box>
            {/* Name */}
            <Box
              sx={{
                color: "#fff",
                alignItems: "start",
                margin: "10px 10px",
              }}
            >
              <InputLabel sx={{ color: "#fff" }}>Name</InputLabel>
              <StyledInputBase
                style={{
                  width: "260px",
                  backgroundColor: "#fff",
                  margin: "10px 0px",
                  borderRadius: "20px",
                }}
                value={user_name}
                type="text"
                onChange={(e) => setUser_Name(e.target.value)}
              />
            </Box>
            {/* User Name */}
            <Box
              sx={{
                color: "#fff",
                alignItems: "start",
                margin: "10px 10px",
              }}
            >
              <InputLabel sx={{ color: "#fff" }}>Username</InputLabel>
              <StyledInputBase
                style={{
                  width: "260px",
                  backgroundColor: "#fff",
                  margin: "10px 0px",
                  borderRadius: "20px",
                }}
                value={user_Username}
                type="text"
                onChange={(e) => setUser_Username(e.target.value)}
              />
            </Box>
            {/* User Pass */}
            <Box
              sx={{
                color: "#fff",
                alignItems: "start",
                margin: "10px 10px",
              }}
            >
              <InputLabel sx={{ color: "#fff" }}>User Password</InputLabel>
              <StyledInputBase
                style={{
                  width: "260px",
                  backgroundColor: "#fff",
                  margin: "10px 0px",
                  borderRadius: "20px",
                }}
                value={user_Pass}
                type="text"
                onChange={(e) => setUser_pass(e.target.value)}
              />
            </Box>
            {/* Area*/}
            <Box
              sx={{
                color: "#fff",
                alignItems: "start",
                margin: "10px 10px",
              }}
            >
              <InputLabel id="demo-multiple-chip-label" sx={{ color: "#fff" }}>
                Area
              </InputLabel>
              <Select
                sx={{
                  width: "260px",
                  backgroundColor: "#fff",
                  borderRadius: "10px",
                  height: "150px",
                  overflow: "scroll",
                }}
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={userArea}
                defaultValue={userArea}
                onChange={handleChangeUserArea}
                renderValue={(selected) => (
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 0.5,
                    }}
                  >
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={value}
                        sx={{ backgroundColor: "#30D479", color: "#fff" }}
                      />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {userData?.areas &&
                  userData.areas.map((areass) => (
                    <MenuItem
                      key={areass.area_name}
                      value={areass.area_name}
                      sx={{
                        backgroundColor: userArea.includes(areass.area_name)
                          ? "#fbbd2c"
                          : "#fff",
                        color: userArea.includes(areass.area_name)
                          ? "#30D479"
                          : "red",
                        "&:hover": {
                          backgroundColor: userArea.includes(areass.area_name)
                            ? "red"
                            : "#30D479",
                        },
                      }}
                    >
                      {areass.area_name}
                    </MenuItem>
                  ))}
              </Select>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              {userEdit === true ? (
                <Button
                  sx={{
                    backgroundColor: "#fbbd2c",
                    color: "#000",
                    "&:hover": { backgroundColor: "#fbbd2c", color: "#000" },
                  }}
                  onClick={handleEditUser}
                >
                  Edit User
                </Button>
              ) : (
                <Button
                  sx={{
                    backgroundColor: "#fbbd2c",
                    color: "#000",
                    "&:hover": { backgroundColor: "#fbbd2c", color: "#000" },
                  }}
                  onClick={handleAddUser}
                >
                  <AddIcon /> Add User
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default Settings;
