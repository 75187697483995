import {
  Box,
  Button,
  Paper,
  InputBase,
  Select,
  MenuItem,
  Icon,
  IconButton,
  FormControl,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import React, { useEffect, useState, useRef, useContext } from "react";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "../../api/axios";
import toast from "react-hot-toast";
import AddCustomerModal from "../../components/modals/addcustomer";
import ViewCustomerModal from "../../components/modals/viewcustomermodal";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Buttons from "../../components/modals/button";
import AuthContext from "../../context/authContext";
import Grids from "../../components/modals/grids";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      color: "#fff",
      backgroundImage: "linear-gradient(to bottom right, #302b63, #24243e)",
    },
  },
};

const CableCustomer = () => {
  const [gridToolbar, setGridToolbar] = useState(false);
  const { userData, setRefresh } = useContext(AuthContext);
  const tableRef = useRef(null);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [area, setArea] = useState("All");
  const [payType, setPayType] = useState("All");
  const [editData, setEditData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [openCustomerModal, setOpenCustomerModal] = useState(false);
  const [openCustomerViewModal, setOpenCustomerViewModal] = useState(false);
  const [custViewData, setCustViewData] = useState([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    type: false,
    status: false, // Hide the 'type' column by default
    registerdate: false,
    installation: false,
  });

  const handleOpenCustomer = () => {
    setOpenCustomerModal(true);

    setEditMode(false);
  };

  const resetCustomerForm = () => {
    setEditMode(false);
    setEditData([]);
  };
  const handleCloseCustomer = () => {
    setOpenCustomerModal(false);
    resetCustomerForm();
    setRefresh(true);
  };

  const handleOpenEditCustomer = (id) => {
    const filterEdit = filteredData.filter((item) => item._id === id);
    setEditData(filterEdit);
    setOpenCustomerModal(true);
    setEditMode(true);
    console.log(filterEdit);
    console.log(editMode);
  };

  const handleOpenCustomerView = () => setOpenCustomerViewModal(true);
  const handleCloseCustomerView = () => {
    setOpenCustomerViewModal(false);
    setArea("All");
    setPayType("All");
    setRefresh(true);
  };

  const handleFilters = () => {
    // Apply area filter
    let filtered =
      userData.customers &&
      userData.customers.filter(
        (filt) =>
          filt.customer_status !== "trash" && filt.customer_status !== "Erased"
      );
    let filteredDataByArea = [];
    if (area === "All") {
      filteredDataByArea = filtered;
    } else {
      filteredDataByArea = filtered.filter((customer) =>
        area.includes(customer.area)
      );
    }

    // Apply payment status filter
    let filteredDataByPaymentStatus = [];
    if (payType !== "All") {
      filteredDataByPaymentStatus = filteredDataByArea.filter(
        (customer) => customer.payment_status === payType
      );
    } else {
      filteredDataByPaymentStatus = filteredDataByArea;
    }

    let filterSearch = [];
    if (!search) {
      filterSearch = filteredDataByPaymentStatus;
    } else {
      //   const searchTerm = search.toLowerCase();
      //   filterSearch = filteredDataByPaymentStatus.filter((customer) =>
      //     Object.values(customer).some((value) => {
      //       if (value === null || value === undefined) return false;
      //       return value.toString().toLowerCase().includes(searchTerm);
      //     })
      //   );
      const searchTerm = search.toLowerCase();
      filterSearch = filteredDataByPaymentStatus.filter((customer) => {
        // Define the specific fields you want to search within
        const { customer_name, mobile, stb_no, caf_no, area } = customer;

        // Check if any of the specified fields contain the search term
        return (
          (customer_name && customer_name.toLowerCase().includes(searchTerm)) ||
          (mobile && mobile.toString().toLowerCase().includes(searchTerm)) ||
          (stb_no && stb_no.toLowerCase().includes(searchTerm)) ||
          (caf_no && caf_no.toLowerCase().includes(searchTerm)) ||
          (area && area.toLowerCase().includes(searchTerm))
        );
      });
    }

    setFilteredData(filterSearch);
  };
  useEffect(() => {
    handleFilters();
  }, [area, search, payType, userData.customers]);

  useEffect(() => {
    const filters = userData?.customers?.filter(
      (cust) =>
        cust.customer_status !== "Erased" && cust.customer_status !== "trash"
    );
    // console.log(filters);

    setFilteredData(filters);
  }, []);

  const handleDeleteCustomer = async (id) => {
    try {
      const res = await axios.put(
        "/deletecust",
        { id },
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
            lcono: localStorage.getItem("lcono"),
          },
        }
      );
      if (res.status === 200) {
        toast.success("Customer Deleted");
        setRefresh(true);
        handleFilters();
      } else {
        toast.error("Please try again");
      }
    } catch (error) {
      console.log("Error deleting Customer", error);
    }
  };
  const handleviewCustomer = (id) => {
    const filters =
      userData.customers &&
      userData.customers.filter((item) => item._id === id);
    setCustViewData(filters);
    handleOpenCustomerView();
  };

  const downloadPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      html: tableRef.current,
      theme: "striped",
      styles: {
        textColor: [0, 0, 0], // White text color for headers
        fontSize: 5, // Font size for the text
        cellPadding: 2, // Padding for each cell
        valign: "middle", // Vertical alignment for all cells
        halign: "center", // Horizontal alignment for all cells
      },
      headStyles: {
        fillColor: [66, 133, 244], // Blue fill color for headers
        textColor: [255, 255, 255],
        fontStyle: "bold", // Bold font style for headers
      },
      bodyStyles: {
        fillColor: false, // No fill color for body
      },
      columnStyles: {
        0: { cellWidth: "auto" }, // Adjust column width automatically
      },
      margin: { top: 20 }, // Set margin for the PDF
    });
    doc.save("customers.pdf");
  };

  // New
  const columns = [
    {
      field: "id",
      headerName: "S.No",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      minWidth: 50,
      flex: 1,
    },
    {
      field: "name",
      headerName: "Name",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "left",
      minWidth: 160,
      flex: 2,
    },
    {
      field: "cafno",
      headerName: "CAF No",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "area",
      headerName: "Area",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      minWidth: 160,
      flex: 1,
    },
    {
      field: "stbno",
      headerName: "STB No",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "mobile",
      headerName: "Mobile",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "installation",
      headerName: "Ins.Charges",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      minWidth: 80,
      flex: 1,
    },
    {
      field: "type",
      headerName: "Type",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      minWidth: 50,
      flex: 1,
      //   hideable: false,
      //   hide: true,
    },
    {
      field: "plan",
      headerName: "Plan",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      minWidth: 50,
      flex: 1,
    },
    {
      field: "balance",
      headerName: "Balance",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      minWidth: 90,
      flex: 1,
      renderCell: (params) => (
        <Box
          sx={{
            color: params.row.cable_balance <= 0 ? "#fff" : "red",
            textAlign: "center",
            whiteSpace: "nowrap",
          }}
        >
          {params.row.cable_balance}
        </Box>
      ),
    },
    {
      field: "registerdate",
      headerName: "Reg.Date",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "lastpaid",
      headerName: "Last Paid",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      minWidth: 80,
      flex: 1,
      renderCell: (params) => (
        <Box
          sx={{
            color: params.row.customer_status === "Active" ? "#30D479" : "red",
          }}
        >
          {params.row.customer_status}
        </Box>
      ),
    },
    {
      field: "payment_status",
      headerName: "Payments",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      minWidth: 80,
      flex: 1,
      renderCell: (params) => (
        <Box
          sx={{
            color: params.row.payment_status === "Paid" ? "#30D479" : "red",
          }}
        >
          {params.row.payment_status}
        </Box>
      ),
    },
    {
      field: "actions",

      headerName: "Actions",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 2,
      minWidth: 120,

      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <IconButton
            sx={{
              color: "#fbbd2c",
              "&:hover": {
                backgroundColor: "#fff",
                color: "#000",
              },
            }}
            onClick={() => handleviewCustomer(params.row._id)}
          >
            <Icon>
              <VisibilityIcon />
            </Icon>
          </IconButton>

          <IconButton
            sx={{
              color: "#fbbd2c",
              "&:hover": {
                backgroundColor: "#fff",
                color: "#000",
              },
            }}
            onClick={() => handleOpenEditCustomer(params.row._id)}
          >
            <Icon>
              <EditIcon />
            </Icon>
          </IconButton>

          <IconButton
            sx={{
              color: "#fbbd2c",
              "&:hover": {
                backgroundColor: "#fff",
                color: "#000",
              },
            }}
            onClick={() => handleDeleteCustomer(params.row.caf_no)}
          >
            <Icon>
              <DeleteIcon />
            </Icon>
          </IconButton>
        </Box>
      ),
    },
  ];
  const rows = filteredData?.map((item, index) => ({
    id: index + 1,
    _id: item._id,
    name: item.customer_name,
    cafno: item.caf_no,
    area: item.area,
    stbno: item.stb_no,
    mobile: item.mobile,
    type: item.stb_type,
    plan: item.cable_plan,
    balance: item.cable_balance,
    lastpaid:
      item.payments && item.payments.length > 0
        ? item.payments
            ?.filter(
              (payment) => payment.remarks === "Regular" && payment.pay_date
            ) // Filter based on remarks and pay_date
            ?.findLast?.((payment) => payment) // Get the last valid payment
            ?.pay_date?.split("-")
            .reverse()
            .join("-") // Format the date as DD-MM-YYYY
        : "New",
    customer_status: item.customer_status,
    payment_status: item.payment_status,
    payments: item.payments,
    caf_no: item.caf_no,
    cable_balance: item.cable_balance,
    installation: item.cable_installation,
    registerdate: item.registerdate
      ? item.registerdate
          .split("T")[0] // Split at 'T' and take the date part
          .split("-") // Split the date part by '-'
          .reverse() // Reverse the array (to get date in DD-MM-YYYY)
          .join("-") // Join the array back with '-'
      : "N/A",
  }));

  return (
    <Box
      sx={{
        display: "flex",
        color: "#fff",
        margin: "20px",
        minHeight: "100vh",
        flexDirection: "column",
        gap: "5px",
      }}
    >
      {/* Top Box */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          flexWrap: "wrap",
          gap: "10px",
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        {/* Left Panel */}
        <Box
          sx={{
            display: "flex",
            gap: "20px",
            justifyContent: { xs: "space-between", sm: "flex-start" },
            alignItems: "center",
            flex: 1,
          }}
        >
          {/* Add Customer */}
          <Box>
            <Button
              title="Add Customer"
              sx={{
                color: "#000",
                backgroundColor: "#fbbd2c",
                // width: { xs: "max-content", sm: "180px" },
                display: "flex",
                alignItems: "center",
                gap: "10px",
                padding: "15px",
                "&:hover": {
                  backgroundColor: "#fbbd2c",
                },
              }}
              onClick={handleOpenCustomer}
            >
              <PersonAddIcon sx={{ fontSize: "20px" }} />

              {/* <Typography
                  sx={{
                    fontWeight: "bolder",
                    display: { xs: "none", sm: "flex" },
                  }}
                >
                  Add Customer
                </Typography> */}
            </Button>
          </Box>
          {/* Enable Filters */}
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            {/* Enable Filters */}
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={gridToolbar}
                    onChange={() => setGridToolbar(!gridToolbar)}
                    name="enableFilters"
                    sx={{
                      padding: 0,
                      color: "#fbbd2c", // Checkbox color when unchecked
                      "&.Mui-checked": {
                        color: "#fbbd2c", // Checkbox color when checked
                      },
                    }}
                  />
                }
                label="Enable Filters"
                sx={{
                  color: "#fbbd2c", // Label color
                  mt: 1,
                }}
              />
            </FormControl>
          </Box>
        </Box>

        {/* Search Input */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: { xs: "100%", sm: "max-content" },
            flex: 1,
          }}
        >
          <Paper
            component="form"
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              backgroundColor: "#fbbd2c",
              borderRadius: "10px",
            }}
          >
            <InputBase
              sx={{
                flex: 1,
                p: 1,
                color: "#000",
              }}
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              inputProps={{ "aria-label": "Search" }}
            />
          </Paper>
        </Box>

        {/* Right Panel */}
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            justifyContent: { xs: "center", sm: "flex-end" },
            width: { xs: " 100%", sm: "max-content" },
            flex: 1,
          }}
        >
          {/* Filter Buttons */}
          <Box
            sx={{
              display: "flex",
              width: "100%",
              gap: "10px",
              justifyContent: "space-between",
              flexDirection: "row",
              width: { xs: "100%", sm: "max-content" },
            }}
          >
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              defaultValue={"All"}
              value={area}
              onChange={(e) => setArea(e.target.value)}
              renderValue={(selected) => selected}
              MenuProps={MenuProps}
              sx={{
                backgroundColor: "#fbbd2c",
                width: "100px",
                height: "50px",
                borderColor: "#fbbd2c",
              }}
            >
              <MenuItem value={"All"}>All</MenuItem>
              {userData &&
                userData.areas &&
                userData.areas.map((item) => (
                  <MenuItem key={item.area_name} value={item.area_name}>
                    {item.area_name}
                  </MenuItem>
                ))}
            </Select>

            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              value={payType}
              onChange={(e) => setPayType(e.target.value)}
              renderValue={(selected) => selected}
              MenuProps={MenuProps}
              sx={{
                backgroundColor: "#fbbd2c",
                width: "100px",
                height: "50px",
                borderColor: "#fbbd2c",
              }}
            >
              <MenuItem value={"All"}>All</MenuItem>
              <MenuItem value={"Paid"}>Paid</MenuItem>
              <MenuItem value={"Unpaid"}>UnPaid</MenuItem>
            </Select>
          </Box>
        </Box>
      </Box>

      {/* Grids */}
      <Box>
        <Grids
          columns={columns}
          rows={rows}
          gridToolbar={gridToolbar}
          searchQuery={search}
          columnVisibilityModel={columnVisibilityModel}
          setColumnVisibilityModel={setColumnVisibilityModel}
        />
      </Box>
      <AddCustomerModal
        openCustomerModal={openCustomerModal}
        handleCloseCustomer={handleCloseCustomer}
        editData={editData}
        editMode={editMode}
      />
      <ViewCustomerModal
        openCustomerViewModal={openCustomerViewModal}
        handleCloseCustomerView={handleCloseCustomerView}
        custViewData={custViewData}
      />
    </Box>
  );
};

export default CableCustomer;
