// import { Navigate, Outlet, useLocation } from "react-router-dom";
// import { jwtDecode } from "jwt-decode";

// const RequireAuth = ({ allowedRoles }) => {
//   let token = localStorage.getItem("token");
//   const location = useLocation();

//   if (token) {
//     const decodedToken = jwtDecode(token);
//     const currentTime = Date.now() / 1000;

//     if (decodedToken.exp < currentTime) {
//       return <Navigate to="/" state={{ from: location }} replace />;
//     } else {
//       return allowedRoles.find((roles) =>
//         decodedToken?.role?.includes(roles)
//       ) ? (
//         <Outlet />
//       ) : (
//         <Navigate to="/" state={{ from: location }} replace />
//       );
//     }
//   } else {
//     return <Navigate to="/" state={{ from: location }} replace />;
//   }
// };

// export default RequireAuth;

// export const getRoles = () => {
//   let token = localStorage.getItem("token");

//   if (token) {
//     const decodedToken = jwtDecode(token);
//     const roles = decodedToken.role;
//     return roles;
//   }

//   // Return a default value if no token is found
//   return [];
// };
// export const user_name = () => {
//   let token = localStorage.getItem("token");

//   if (token) {
//     const decodedToken = jwtDecode(token);
//     const user_name = decodedToken.user_name;
//     return user_name;
//   } else {
//     return [];
//   }
//   // Return a default value if no token is found
// };

import { Navigate, Outlet, useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const RequireAuth = ({ allowedRoles }) => {
  let token = localStorage.getItem("token");
  const location = useLocation();

  if (token) {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;

    // Token is valid only if decodedToken.exp > currentTime
    if (decodedToken.exp > currentTime) {
      return allowedRoles.some((role) => decodedToken?.role?.includes(role)) ? (
        <Outlet />
      ) : (
        <Navigate to="/" state={{ from: location }} replace />
      );
    } else {
      // Token expired
      return <Navigate to="/" state={{ from: location }} replace />;
    }
  }

  // No token found
  return <Navigate to="/" state={{ from: location }} replace />;
};

export const getRoles = () => {
  let token = localStorage.getItem("token");

  if (token) {
    const decodedToken = jwtDecode(token);
    return decodedToken.role || [];
  }

  return [];
};

export const user_name = () => {
  let token = localStorage.getItem("token");

  if (token) {
    const decodedToken = jwtDecode(token);
    return decodedToken.user_name || "";
  }

  return "";
};

export default RequireAuth;
