import {
  Box,
  Typography,
  styled,
  MenuItem,
  Select,
  InputLabel,
  InputBase,
  Button,
  FormControlLabel,
  FormControl,
  Checkbox,
} from "@mui/material";
import React, { useRef, useState, useContext } from "react";
import AuthContext from "../../context/authContext";
import Grids from "../../components/modals/grids";

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  width: "150px",
  height: "35px",
  backgroundColor: "#fff",
  margin: "10px 0px",
  padding: "0px 15px",
  borderRadius: "20px",
  "&::placeholder": {
    marginLeft: "10px",
    color: theme.palette.text.secondary, // You can adjust the color as needed
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  width: "200px",
  height: "35px",
  backgroundColor: "#fff",
  margin: "10px 0px",
  padding: "0px 15px",
  borderRadius: "20px",
  "&::placeholder": {
    marginLeft: "10px",
    color: theme.palette.text.secondary, // You can adjust the color as needed
  },
}));

const getCurrentDate = () => {
  return new Date().toISOString().split("T")[0];
};

const Collections = () => {
  const { userData } = useContext(AuthContext);
  const tableRef = useRef();
  const [reports, setreports] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [search, setSearch] = useState([]);
  const inputRefArea = useRef("");
  const [inpUser, setInpUser] = useState("All");
  const inputRefFromDate = useRef(null);
  const inputRefToDate = useRef(null);
  const [gridToolbar, setGridToolbar] = useState(false);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    type: false,
    status: false, // Hide the 'type' column by default
    registerdate: false,
    installation: false,
  });

  const handleGetPayments = async () => {
    const fromdate = inputRefFromDate.current.value;
    const todate = inputRefToDate.current.value;
    const area = inputRefArea.current.value;

    // Filter data by area
    let filteredDataByArea = [];

    if (area === "All") {
      filteredDataByArea = userData.customers;
    } else {
      filteredDataByArea = userData.customers.filter((customer) =>
        area.includes(customer.area)
      );
    }

    // Filter payments based on date range from the filtered data
    const filteredPayments = filteredDataByArea.flatMap((customer) =>
      customer.payments.filter((payment) => {
        // const paymentDate = new Date(payment.pay_date)
        //   .toISOString()
        //   .split("T")[0];
        return payment.pay_date >= fromdate && payment.pay_date <= todate;
      })
    );

    let filteredUsers;
    if (inpUser === "All") {
      filteredUsers = filteredPayments;
    } else {
      filteredUsers = filteredPayments.filter(
        (payment) => payment.collected_by === inpUser
      );
      // console.log(filteredPayments.map((payment) => payment));
    }

    // Sorting payments
    // filteredPayments.sort((a, b) => {
    //   return new Date(a.pay_date) - new Date(b.pay_date);
    // });
    // filteredUsers.reverse();
    // Set the filtered data to state
    setreports(filteredUsers);
    setSearch(filteredUsers);
    setShowTable(true);
  };
  const checkDuplicate = (payment, index, payments) => {
    console.log("Checking duplicate for payment:", payment);
    return payments.some(
      (p, i) =>
        i !== index &&
        p.customer_name === payment.customer_name &&
        p.device_no === payment.device_no
    );
  };

  const handleSearch = (text) => {
    const searchFilter = search;

    // If the search text is empty, reset the reports to the original data
    if (!text.trim()) {
      setreports(searchFilter);
      return;
    }

    const filteredReports = searchFilter.filter((customer) =>
      Object.values(customer).some((value) => {
        if (value === null || value === undefined) return false;
        return value.toString().toLowerCase().includes(text.toLowerCase());
      })
    );

    setreports(filteredReports);
    calculateTotalPayments();
  };

  const calculateTotalPayments = () => {
    return reports.reduce((total, payment) => total + payment.amount, 0);
  };

  const columns = [
    {
      field: "id",
      headerName: "S.No",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      minWidth: 50,
      flex: 1,
    },
    {
      field: "name",
      headerName: "Name",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      minWidth: 180,
      flex: 1,
    },
    {
      field: "cafno",
      headerName: "CAF No",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      minWidth: 130,
      flex: 1,
    },
    {
      field: "area",
      headerName: "Area",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      minWidth: 180,
      flex: 1,
    },
    {
      field: "mobile",
      headerName: "Mobile",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "device_no",
      headerName: "STB No",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      flex: 1,

      renderCell: (params) => {
        return (
          <Box
            sx={{
              textAlign: "center",
              color:
                userData?.customers.filter((customer) =>
                  customer.payments.some(
                    (payment) => payment._id === params.row._id
                  )
                )[0].stb_no !== params.row.device_no
                  ? "red"
                  : "inherit",
            }}
          >
            {params.row.device_no}
          </Box>
        );
      },
    },
    {
      field: "paymentdate",
      headerName: "Pay Date",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "amount",
      headerName: "Payments",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      minWidth: 120,
      flex: 1,
      renderCell: (params) => (
        <Box
          sx={{
            textAlign: "center",
            color:
              params.row.amount === params.row.bill_amount ? "inherit" : "red",
          }}
        >
          {params.row.amount}
        </Box>
      ),
    },
    {
      field: "collectedby",
      headerName: "Agent",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      flex: 1,
    },
  ];

  const rows = reports?.map((item, index) => ({
    id: index + 1,
    _id: item._id,
    name: item.customer_name,
    paymentdate: item.pay_date,
    collectedby: item.collected_by,
    amount: item.amount,
    bill_amount: item.bill_amount,
    device_no: item.device_no,
    cafno:
      userData?.customers
        .filter((customer) =>
          customer.payments.some((payment) => payment._id === item._id)
        )[0]
        ?.caf_no?.toUpperCase() || "",
    area: userData?.customers.filter((customer) =>
      customer.payments.some((payment) => payment._id === item._id)
    )[0]?.area,
    mobile: userData?.customers.filter((customer) =>
      customer.payments.some((payment) => payment._id === item._id)
    )[0]?.mobile,
  }));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        minHeight: "100vh",
        padding: "15px 20px",
      }}
    >
      <Typography
        sx={{
          color: "#fbbd2c",
          textAlign: "center",
          fontSize: "20px",
          fontWeight: "bolder",
        }}
      >
        Payment Report
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
        {" "}
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          {/* From Date */}
          <Box
            sx={{
              color: "#fff",
              alignItems: "start",
              margin: "10px 10px",
            }}
          >
            <InputLabel
              sx={{ color: "#fff", display: { xs: "none", sm: "block" } }}
            >
              From Date
            </InputLabel>
            <StyledInputBase
              defaultValue={getCurrentDate()}
              placeholder="Register Date"
              type="date"
              inputProps={{ ref: inputRefFromDate }}
            />
          </Box>
          {/* To Date */}
          <Box
            sx={{
              color: "#fff",
              alignItems: "start",
              margin: "10px 10px",
            }}
          >
            <InputLabel
              sx={{ color: "#fff", display: { xs: "none", sm: "block" } }}
            >
              To Date
            </InputLabel>
            <StyledInputBase
              defaultValue={getCurrentDate()}
              placeholder="Register Date"
              type="date"
              inputProps={{ ref: inputRefToDate }}
            />
          </Box>
        </Box>
        {/* Options */}
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          {/* Report Type
            <Box
              sx={{
                color: "#fff",
                alignItems: "start",
                margin: "10px 10px",
              }}
            >
              <InputLabel sx={{ color: "#fff" }}>Type of Report</InputLabel>
              <StyledSelect
                displayEmpty
                defaultValue="All"
                inputProps={{
                  "aria-label": "Without label",
                  ref: inputRefType,
                }}
              >
                <MenuItem value={"All"}>All</MenuItem>
                <MenuItem value={"Paid"}>Paid</MenuItem>
                <MenuItem value={"Unpaid"}>Unpaid</MenuItem>
              </StyledSelect>
            </Box> */}
          {/* Area Wise */}
          <Box
            sx={{
              color: "#fff",
              alignItems: "start",
              margin: "10px 10px",
            }}
          >
            <InputLabel
              sx={{ color: "#fff", display: { xs: "none", sm: "block" } }}
            >
              Area
            </InputLabel>

            <StyledSelect
              displayEmpty
              defaultValue="All"
              sx={{ width: { xs: "120px", sm: "200px" } }}
              inputProps={{
                "aria-label": "Without label",
                ref: inputRefArea,
              }}
            >
              <MenuItem value={"All"}>All</MenuItem>
              {userData.areas &&
                userData.areas.map((item) => (
                  <MenuItem value={item.area_name} key={item.area_name}>
                    {item.area_name}
                  </MenuItem>
                ))}
            </StyledSelect>
          </Box>
          {/* UserWise */}
          <Box
            sx={{
              color: "#fff",
              alignItems: "start",
              margin: "10px 10px",
            }}
          >
            <InputLabel
              sx={{ color: "#fff", display: { xs: "none", sm: "block" } }}
            >
              User
            </InputLabel>
            <StyledSelect
              displayEmpty
              defaultValue={"All"}
              value={inpUser}
              onChange={(e) => setInpUser(e.target.value)}
              sx={{ width: { xs: "120px", sm: "200px" } }}
              inputProps={{
                "aria-label": "Without label",
              }}
            >
              <MenuItem value={"All"}>All</MenuItem>
              <MenuItem value={userData?.name}>
                {userData?.name?.toUpperCase()}
              </MenuItem>
              {userData.users &&
                userData.users.map((item) => (
                  <MenuItem value={item.name} key={item.name}>
                    {item.name}
                  </MenuItem>
                ))}
            </StyledSelect>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "20px",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <Button
          sx={{
            color: "#000",
            backgroundColor: "#fbbd2c",
            "&:hover": {
              color: "#000",
              backgroundColor: "#fbbd2c",
            },
          }}
          onClick={handleGetPayments}
        >
          view
        </Button>
        {showTable && (
          <>
            <input
              style={{
                backgroundColor: "#fbbd2c",
                borderRadius: "20px",
                width: "200px",
                height: "30px",
                outline: "none",
              }}
              onChange={(e) => handleSearch(e.target.value)}
              placeholder="Search"
            />
            <Typography sx={{ color: "#fbbd2c" }}>
              Count: {reports.length}
            </Typography>
          </>
        )}
        {showTable && (
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={gridToolbar}
                  onChange={() => setGridToolbar(!gridToolbar)}
                  name="enableFilters"
                  sx={{
                    padding: 0,
                    color: "#fbbd2c", // Checkbox color when unchecked
                    "&.Mui-checked": {
                      color: "#fbbd2c", // Checkbox color when checked
                    },
                  }}
                />
              }
              label="Enable Filters"
              sx={{
                color: "#fbbd2c", // Label color
              }}
            />
          </FormControl>
        )}
        <Typography
          sx={{ color: "#fbbd2c", fontSize: { xs: "16px", sm: "20px" } }}
        >
          Total: {reports?.reduce((acc, curr) => acc + curr.amount, 0)}
        </Typography>
      </Box>
      {/* List */}
      {showTable && (
        <Grids
          columns={columns}
          rows={rows}
          gridToolbar={gridToolbar}
          //   searchQuery={search}
          columnVisibilityModel={columnVisibilityModel}
          setColumnVisibilityModel={setColumnVisibilityModel}
        />
      )}
    </Box>
  );
};

export default Collections;
